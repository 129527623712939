var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"Table"},[_c('table',{staticClass:"Table__table",style:({minWidth: _vm.tableWidth()+'px' })},[_c('tr',{staticClass:"Table__table-row Table__table-row_header"},_vm._l((_vm.columns.models),function(column){return _c('th',{key:column.key,class:['Table__table-header', column.key],style:({ width: column.width })},[_c('div',{staticClass:"Table__table-header-container"},[_vm._t(`table-header-${column.key}`,function(){return [_c('div',{class:{'Table__table-header-content': true, 'sorting': column.sort},on:{"click":function($event){return _vm.emitSort(column)}}},[_c('span',{class:{'with-word-break': column.header.length > 10}},[_vm._v(_vm._s(column.header))]),(column.sort)?_c('span',{class:{
                'table-header-sort-arrow': true,
                ascending: true,
                'active': column.sortDirection === 'asc'
              }},[_vm._v("▲")]):_vm._e(),(column.sort)?_c('span',{class:{
                'table-header-sort-arrow': true,
                descending: true,
                'active': column.sortDirection === 'desc'
              }},[_vm._v("▼")]):_vm._e()])]}),(column.key === 'rowSelect')?_vm._t(`table-header-bulk`):_vm._e()],2)])}),0),_vm._l((_vm.collection.models),function(model,index){return [_c('tr',{key:`table-row-data:${index}`,class:[
          'Table__table-row',
          'Table__table-row_data',
          {'Table__table-row_data-first': index === 0},
          {'Table__table-row_data-last': index === (_vm.collection.models.length - 1)},
          ..._vm.getRowClasses(model)
        ]},_vm._l((_vm.columns.models),function(column){return _c('td',{key:`table-cell:${index}:${column.key}`,class:['Table__table-cell', `Table__table-cell_${column.key}`]},[_c('div',{staticClass:"Table__table-cell-container"},[_vm._t(`table-cell-${column.key}`,function(){return [_c('div',{class:['Table__table-cell-content', `Table__table-cell-content_${column.key}`]},[_vm._v(" "+_vm._s(_vm.get(_vm.collection.models, `[${index}].${column.key}]`))+" ")])]},{"model":model,"column":column,"index":index,"data":_vm.get(_vm.collection.models, `[${index}].${column.key}]`)})],2)])}),0),(_vm.detailsIndex === index)?_c('tr',{key:`table-row-details:${index}`,staticClass:"bbox"},[_c('td',{staticClass:"bbox",attrs:{"colspan":_vm.columns.models.length}},[_vm._t("table-details",null,{"model":model,"index":index})],2)]):_vm._e()]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }