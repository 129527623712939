<template>
  <section class="Table">
    <table class="Table__table" :style="{minWidth: tableWidth()+'px' }">
      <tr class="Table__table-row Table__table-row_header">
        <th v-for="column in columns.models" :key="column.key"
            :class="['Table__table-header', column.key]" :style="{ width: column.width }">
          <div class="Table__table-header-container">
            <slot :name="`table-header-${column.key}`">
              <div :class="{'Table__table-header-content': true, 'sorting': column.sort}"
                  @click="emitSort(column)">
                <span :class="{'with-word-break': column.header.length > 10}">{{column.header}}</span>
                <span v-if="column.sort" :class="{
                  'table-header-sort-arrow': true,
                  ascending: true,
                  'active': column.sortDirection === 'asc'
                }">▲</span>
                <span v-if="column.sort" :class="{
                  'table-header-sort-arrow': true,
                  descending: true,
                  'active': column.sortDirection === 'desc'
                }">▼</span>
              </div>
            </slot>

            <slot :name="`table-header-bulk`" v-if="column.key === 'rowSelect'"> </slot>
          </div>
        </th>
      </tr>
      <template v-for="(model, index) in collection.models">
        <tr
            :class="[
            'Table__table-row',
            'Table__table-row_data',
            {'Table__table-row_data-first': index === 0},
            {'Table__table-row_data-last': index === (collection.models.length - 1)},
            ...getRowClasses(model)
          ]"
            :key="`table-row-data:${index}`"
        >
          <td
              :class="['Table__table-cell', `Table__table-cell_${column.key}`]"
              v-for="column in columns.models"
              :key="`table-cell:${index}:${column.key}`"
          >
            <div class="Table__table-cell-container">
              <slot
                  :name="`table-cell-${column.key}`"
                  :model="model" :column="column"
                  :index="index"
                  :data="get(collection.models, `[${index}].${column.key}]`)"
              >
                <div :class="['Table__table-cell-content', `Table__table-cell-content_${column.key}`]">
                  {{ get(collection.models, `[${index}].${column.key}]`) }}
                </div>
              </slot>
            </div>
          </td>
        </tr>
        <tr v-if="detailsIndex === index" :key="`table-row-details:${index}`" class="bbox">
          <td :colspan="columns.models.length" class="bbox">
            <slot
                name="table-details"
                :model="model"
                :index="index"
            />
          </td>
        </tr>
      </template>
    </table>
  </section>
</template>

<script>
import {CollectionAbstraction} from "@/classes/abstractions/collection.abstraction";
import {TableColumnsCollection} from "@/components/Table/classes/TableColumns.collection";
import get from 'lodash.get';

export default {
  name: 'DDMCAF-Table',
  props: {
    collection: {type: CollectionAbstraction},
    columns: {type: TableColumnsCollection, validator: collection => collection.validate()},
    detailsIndex: {type: Number, required: false},
    setRowClasses: {type: Function, required: false}
  },
  methods: {
    get,
    emitSort(data) {
      for(let item of this.columns.models) {
        if(data.key !== item.key){
          item.sortDirection = ''
        }
      }

      if(data.sortDirection === '' || data.sortDirection === 'desc') {
        data.sortDirection = 'asc';
      } else {
        data.sortDirection = 'desc';
      }
      this.$emit('on-sort', data)
    },
    tableWidth() {
      return this.columns.models
          .map((column) => parseInt(column.width, 10))
          .reduce((acc, current) => acc+current, 0);
    },
    getRowClasses(key){
      if(!this.setRowClasses){
        return [];
      }
      return this.setRowClasses(key) || [];
    },
  }
}
</script>

<style lang="scss">
@import "src/styles/main.scss";

.checkAll{
  margin: 0;
}
.Table {
  .rowSelect{
    margin: 0;
  }

  width: 100%;
  $border-radius: 15px;
  .Table__table-row_header{
    background:#D8DAE3;
    border-bottom: 2px solid #f5f5f7;
    th:first-child{
      border-top-left-radius: 10px;
    }
    th:last-child{
      border-top-right-radius: 10px;
    }
    .Table__table-header-container{
      padding: 15px 0;
    }
    .Table__table-header{
      color: #1F1F1F;
    }
  }

  .with-word-break {
    word-break: break-all;
  }

  &__table-cell-container {
    font-size: 12px;
  }

  &__table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;

    .Table__table-row_data:not(.Table__table-row_data-last) {
      .Table__table-cell {
        border-bottom: 2px solid #f5f5f7
      }
    }

    &-header,
    &-cell {
      text-align: left;
      font-size: 13px;
      font-weight: 600;
      color: #8f9093;
      vertical-align: middle;

      &-container {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 15px 2px;
      }

      &:first-of-type {
        .Table__table-header-container,
        .Table__table-cell-container {
          margin-left: 15px
        }
      }

      &:last-of-type {
        .Table__table-header-container,
        .Table__table-cell-container {
          margin-right: 15px
        }
      }
    }

    &-header {
      font-size: 12px;
      text-transform: uppercase;

      &-content {
        cursor: pointer;
        position: relative;
        padding-right: 5px;

        &.sorting {
          padding-right: 13px;

          .table-header-sort-arrow {
            font-size: 7px;
            position: absolute;
            line-height: 7px;
            right: 5px;

            &.ascending {
              bottom: 50%;
            }

            &.descending {
              top: 50%;
            }

            &.active {
              color: $secondary-color;
            }
          }
        }
      }
    }

    &-row_data {
      background-color: white
    }

    &-row_data-first {
      .Table__table-cell:first-of-type {
        border-top-left-radius: $border-radius
      }

      .Table__table-cell:last-of-type {
        border-top-right-radius: $border-radius
      }
    }

    &-row_data-last {
      .Table__table-cell:first-of-type {
        border-bottom-left-radius: $border-radius
      }

      .Table__table-cell:last-of-type {
        border-bottom-right-radius: $border-radius
      }

      .Table__table-cell-container {
        border-bottom: none
      }
    }

  }

  .button-component {
    margin: 0
  }

  .form-group {
    margin: 0
  }

  .bv-no-focus-ring, .form-group {
    height: 100%
  }

  .multiselect__content {
    max-height: 1000px;
  }

  .bbox {
    box-sizing: border-box;
  }
}
</style>
