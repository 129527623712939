import {ModelAbstraction} from "@/classes/abstractions/model.abstraction";
import {IsOptional, IsString} from "class-validator";

export class TableColumnModel extends ModelAbstraction {
  @IsString()
  key;

  @IsString()
  header;

  @IsOptional()
  @IsString()
  width;

  @IsOptional()
  @IsString()
  sort;

  @IsOptional()
  @IsString()
  sortDirection

  constructor(key, header, width, sort = '', sortDirection = '') {
    super();
    this.key = key;
    this.header = header;
    this.width = width;
    this.sort = sort;
    this.sortDirection = sortDirection;
  }
}
