export default {
  ACCREDITATION: 'fma-accreditation',
  ADD_MEMBER: 'fma-add-member',
  ADD_MEMBERS: 'fma-add-members',
  ADD_OUTLINE: 'fma-add-outline',
  ADD: 'fma-add',
  BIN_OUTLINE: 'fma-bin-outline',
  BIN: 'fma-bin',
  CATEGORIES: 'fma-categories',
  DOWN_OUTLINE: 'fma-down-outline',
  DOWN: 'fma-down',
  EDIT_OUTLINE: 'fma-edit-outline',
  EDIT: 'fma-edit',
  EVENTS: 'fma-events',
  HOME: 'fma-home',
  LOG_OUT: 'fma-log-out',
  NOTIFICATION: 'fma-notification',
  PARTNER: 'fma-partner',
  PERSON: 'fma-person',
  PIN: 'fma-pin',
  RADAR: 'fma-radar',
  REMOVE_OUTLINE: 'fma-remove-outline',
  REMOVE: 'fma-remove',
  REQUESTS: 'fma-requests',
  RIGHT_OUTLINE: 'fma-right-outline',
  TEAM: 'fma-team',
  TICKET: 'fma-ticket',
  UP_OUTLINE: 'fma-up-outline',
  UP: 'fma-up',
  VENUE: 'fma-venue-2',
  APPROVAL: 'fma-approval',
  BRAND_ASSETS: 'fma-brandassets',
}
