import {CollectionAbstraction} from "@/classes/abstractions/collection.abstraction";
import {IsInstance, ValidateNested} from "class-validator";
import {TableColumnModel} from './TableColumn.model';

export class TableColumnsCollection extends CollectionAbstraction {
  @IsInstance(TableColumnModel)
  @ValidateNested()
  models;

  constructor(models = []) {
    super(models);
    this.models = [...models];
  }
}
