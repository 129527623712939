<template>
    <div class="ContactsPopup">
        <div class="ContactsPopup__window">
            <div class="ContactsPopup__close-popup">
                <span @click="closePopup">X</span>
            </div>

            <Headline :size="4">Duplicate Person(s) Found</Headline>

            <Table
                    :collection="duplicatePersons"
                    :columns="tableColumns"
            >
              <template v-slot:table-cell-email="slot">
                <span class="with-word-break">
                {{ slot.model.email || '--' }}
                </span>
              </template>
            </Table>

            <div class="btn-wrapper">
                <Button
                        type="primary"
                        @click="createPerson()"
                >
                    Create Anyway
                </Button>
            </div>

        </div>
    </div>
</template>

<script>
    import Headline from "@/components/Headline/Headline.component";
    import Button from "@/components/Button/Button.component";
    import Table from '@/components/Table/Table.component';
    import {TableColumnModel} from "@/components/Table/classes/TableColumn.model";
    import {TableColumnsCollection} from "@/components/Table/classes/TableColumns.collection";

    export default {
        name: "DDMCAF-DuplicatePersonPopup",
        components: {
            Headline,
            Button,
            Table
        },
        props: [
            'duplicatePersons'
        ],
        data: () => ({
            tableColumns: []
        }),
        methods: {
            /**
             * create duplicate person
             */
            createPerson() {
                this.$emit('createDuplicate')
            },
            closePopup() {
                this.$emit('closePopup')
            },
            setTableColumns() {
                let models = [
                    new TableColumnModel('firstName', 'First Name', '25px'),
                    new TableColumnModel('lastName', 'Last Name', '25px'),
                    new TableColumnModel('birthDate', 'Date of Birth', '25px'),
                    new TableColumnModel('email', 'Email', '30px'),
                ]
                this.tableColumns = new TableColumnsCollection(models);
            },
        },
        /**
         * triggered after popup created
         */
        created() {
            this.setTableColumns()
        },
    }
</script>

<style lang="scss">
    .ContactsPopup {
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, .5);
        z-index: 100;
        padding: 20px;
        overflow: scroll;

        &__errors {
            color: darkred;
            font-size: 12px;
        }

        &__window {
            width: 750px;
            padding: 20px;
            border-radius: 20px;
            background: #fff;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }

        &__close-popup {
            width: 100%;
            text-align: right;
            font-weight: bold;
            margin-bottom: 20px;

            span {
                cursor: pointer;
            }
        }

        &__hidden-label {
            .InputLabel__label {
                color: #fff;
            }
        }

        .btn-wrapper {
            margin-top: 30px;
        }

        .InputLayout {
            margin-bottom: 10px;
        }

        .Input__input, .multiselect, .InputLayout__slot {
            width: 100% !important;
        }

        .multiselect__single, .multiselect__option {
            text-transform: capitalize;
        }

        .Input__input {
            padding: 10px 15px;
        }

        .InputLabel__label {
            width: 150px;
            overflow: visible;
            font-weight: 400;
            font-size: 16px;
        }
    }
</style>
