import {v4 as uuidGenerator} from 'uuid';
import {set} from "lodash";

export default {
  created() {
    const uuid = uuidGenerator();
    set(this, '$static.input.id', `Input-${uuid}`);
  },
  methods: {
    eventEmitterInput(event, parser = value => value) {
      const value = this.extractInputValueFromEvent(event);
      this.$emit('input', parser(value));
      return this;
    },
    eventEmitterSubmit(event) {
      const value = this.extractInputValueFromEvent(event);
      this.$emit('submit', value);
      return this;
    },
    extractInputValueFromEvent(event) {
      if (event instanceof InputEvent) return event.target.value;
      return event;
    }
  }
}
