<template>
  <i
    :class="[iconName]"
    :style="[colorStyle, fontSizeStyle]"
    class="fma-icon"
  />
</template>

<script>
import Icons from './Icon.list';

export default {
  name: 'DDMCAF-Icon',
  props: {
    color: {type: String, default: ''},
    colorHex: {type: String, default: ''},
    size: {type: String, default: '24px'},
    icon: {type: String, required: true, validator: (value) => Object.values(Icons).includes(value)},
  },
  computed: {
    iconName() {
      if (this.icon) return this.icon.trim();
      if (this.$slots.default) return this.$slots.default[0].text.trim();
      return '';
    },
    colorStyle() {
      // if (this.colorHex && (/^#[0-9a-f]{3,6}$/i).test(this.colorHex)) return {color: this.colorHex};
      return {color: this.color};
    },
    fontSizeStyle() {
      return {fontSize: this.size};
    }
  }
}
</script>

<style lang="scss">
@import "src/styles/mixins/font-face.mixin";

@include font-face('icons', '../../assets/DDMCAF/fonts/icons/FMA-icons', 'FMA-icons');

//noinspection CssNoGenericFontName
[class^="fma-"], [class*=" fma-"] {
  font-family: 'icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fma-brandassets:before { content: "\e900"; }
.fma-approval:before { content: "\e901"; }
.fma-accreditation:before { content: "\e902"; }
.fma-add-member:before { content: "\e903"; }
.fma-add-members:before { content: "\e904"; }
.fma-add-outline:before { content: "\e905"; }
.fma-add:before { content: "\e906"; }
.fma-bin-outline:before { content: "\e907"; }
.fma-bin:before { content: "\e908"; }
.fma-categories:before { content: "\e909"; }
.fma-down-outline:before { content: "\e90a"; }
.fma-down:before { content: "\e90b"; }
.fma-edit-outline:before { content: "\e90c"; }
.fma-edit:before { content: "\e90d"; }
.fma-events:before { content: "\e90e"; }
.fma-home:before { content: "\e90f"; }
.fma-log-out:before { content: "\e910"; }
.fma-notification:before { content: "\e911"; }
.fma-partner:before { content: "\e912"; }
.fma-person:before { content: "\e913"; }
.fma-pin:before { content: "\e914"; }
.fma-radar:before { content: "\e915"; }
.fma-remove-outline:before { content: "\e916"; }
.fma-remove:before { content: "\e917"; }
.fma-requests:before { content: "\e918"; }
.fma-right-outline {
  rotate: 270deg;
  &:before { content: "\e90a"; }
}
.fma-team:before { content: "\e919"; }
.fma-ticket:before { content: "\e91a"; }
.fma-up-outline:before { content: "\e91b"; }
.fma-up:before { content: "\e91c"; }
.fma-venue-2:before { content: "\e91d"; }
.fma-cross:before { content: "\e91e"; }
.fma-check:before { content: "\e91f"; }
</style>
