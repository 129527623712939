<template>
  <section class="bulk-upload">
    <div :class="{'bulk-upload-component': true, 'uploading': uploading, 'drag-over': hovered}"
         @drop.prevent="callDrop($event)" @dragover.prevent="hovered= true"
         @dragend="hovered = false"
         @dragleave="hovered = false">
      <div class="spinner-overlay">
        <b-spinner variant="primary"></b-spinner>
      </div>
      <div class="bulk-upload-component__header">
        <div class="row header-container">
          <p class="col-lg-4 title "> {{ title }}: </p>
          <div class="col-lg-6 mt-3 mt-lg-0">
            <div class="drop-files" ref="dropzone" @click="callInput()">
              <p>Drop files or <span>browse</span></p>
            </div>
          </div>
          <div class="col-lg-2" v-if="files.length">
            <b-btn variant="primary" @click="files=[]">
              <b-icon icon="arrow-repeat" title="clear" />
            </b-btn>
          </div>
        </div>
      </div>
      <input
          hidden
          multiple
          class="bulk-input-image__input"
          ref="input"
          type="file"
          @change="inputChange($event)"
      />
      <div class="thumbnail-image__container"  >
        <div class="thumbnail-image" :style="thumbnailStyle" v-for="(file, index) in files" :key="index">
          <div class="status">
            <b-btn v-if="file.success === undefined" class="remove" variant="danger" size="sm" @click="remove(index)">
              <b-icon-trash title="remove" />
            </b-btn>
            <b-icon-check v-if="file.success"
                          class="bg-success rounded-circle" variant="light"
                          title="Uploaded successfully"/>
            <b-icon-exclamation v-if="file.success !== undefined && !file.success"
                                class="bg-danger rounded-circle" variant="light"
                                :title="file.error" />
          </div>
          <img v-if="file.type.indexOf('image/') === 0"
              class="InputImage__content-image"
              :src="uncached(file.data)"
          />
          <img v-else
              class="InputImage__content-image"
              :src="imageUrl(file.type)"
          />
        </div>
      </div>
    </div>
    <b-modal ref="selectErrors" title="Some files could not be selected" centered ok-only
             @hidden="selectErrors.length = 0">
      <table class="table table-condensed table-striped">
        <thead>
          <tr>
            <th>File</th>
            <th>Error</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in selectErrors" :key="e.name">
            <td>{{e.name}}</td>
            <td>{{e.error}}</td>
          </tr>
        </tbody>
      </table>
    </b-modal>
    <Button class="upload-image" @click="uploadFiles" :disabled="files.length === 0 || uploading">Upload&nbsp;here</Button>
  </section>
</template>

<script>
import Button from "@/components/Button/Button.component";

const ICON_MAPPING = {
  'application/pdf': 'pdf',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'xls',
  'application/vnd.ms-excel' : 'xls',
};

export default {
  name: 'DDMCAF-BulkFileUpload',
  components: {
    Button,
  },
  props: {
    allowedFileTypes: Array,
    maxSize: Number,
    thumbnailStyle: Object,
    upload: Function,
    title: String,
    type: String,
    api: String,
  },
  data() {
    return {
      hovered: false,
      files: [],
      uploadErrors: [],
      selectErrors: [],
      successMessage: '',
      uploading: false,
    }
  },
  methods: {
    imageUrl(fileType) {
      const path = require.context('@/assets/', false, /\.png$/);
      return path('./icon-' + (ICON_MAPPING[fileType] || 'file') + '.png')
    },

    callDrop(event) {
      this.successMessage = '';
      this.hovered = false;
      const items = event.dataTransfer.items;
      for (let i = 0; i < items.length; i++) {
        this.inputHandle(items[i].getAsFile());
      }
      this.showSelectErrors();
    },

    inputChange(event) {
      this.successMessage = '';
      const fileList = event.target.files;
      for (let i = 0; i < fileList.length; i++) {
        this.inputHandle(fileList[i]);
      }
      this.showSelectErrors();
    },

    showSelectErrors() {
      if (this.selectErrors.length > 0) {
        this.$refs.selectErrors.show();
      }
    },

    callInput() {
      this.$refs.input.click();
    },

    inputHandle(file) {
      // @todo maybe we should show some error message when the file did not get accepted
      if (!file) return;
      if (!this.allowedFileTypes.includes(file.type)) {
        this.selectErrors.push({
          name: file.name,
          error: 'File type is not allowed',
        });
        return;
      }
      if (this.maxSize && file.size > this.maxSize) {
        this.selectErrors.push({
          name: file.name,
          error: 'File size too large',
        });
        return;
      }
      if (this.files.map((f) => f.name).includes(file.name)) {
        this.selectErrors.push({
          name: file.name,
          error: 'File already selected',
        });
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        file.data = reader.result;
        this.files.push(file);
      }
      reader.readAsDataURL(file)
    },

    uncached(image) {
      const url = new URL(image);
      if (url.protocol === 'data:') {
        return image;
      }
      url.searchParams.append('_', (new Date).getTime());
      return url.toString();
    },

    remove(key) {
      this.$delete(this.files, key);
    },

    uploadFiles() {
      this.uploading = true;
      this.uploadErrors.length = 0;
      Promise.all(this.files.map((file) => {
        return this.upload(file).catch((reason) => {
          this.uploadErrors.push(reason);
          return false;
        });
      })).then(() => {
        this.uploading = false;
        // this.files.length = 0;
        if (this.uploadErrors.length === 0) {
          this.successMessage = 'Photos uploaded successFully';
        }
      });
    },
  }
}
</script>

<style lang="scss">
@import "src/styles/main.scss";

.bulk-upload {
  width: 75%;
}

.bulk-upload-component {
  margin-top: 30px;
  height: 400px;
  border-radius: 12px;
  border: solid 2px $primary-color;
  padding: 20px 10px;
  overflow: hidden;
  position: relative;

  .spinner-overlay {
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,0.8);
    z-index: 10;
  }

  &.uploading .spinner-overlay {
    display: flex;
  }

  &__header {
    width: 100%;
    height: 50px;

    .header-container {
      display: flex;
      align-items: center;
    }

    .title {
      font-size: 20px;
      font-weight: bold;
      color: $primary-color;
    }

    .drop-files {
      border: dotted 2px $primary-color;
      display: flex;
      align-items: center;
      padding-left: 10px;
      border-radius: 12px;
      height: 40px;
      font-weight: bold;
      cursor: pointer;
      color: grey;

      p::before {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg version='1.0' xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 15 15'%3E%3Cpath fill='grey' d='M6.4 1.2c-.5.7-.7 2.1-.5 3.1.2 1.3-.3 1.8-1.6 1.6-1-.2-2.4 0-3.1.5-1.9 1.2.4 3.5 2.9 2.9 1.6-.5 2-.2 1.8 1.3-.2 1 0 2.5.5 3.2 1.2 2 3.5-.4 2.8-3-.4-1.8-.2-2 1.6-1.6 2.6.7 5-1.6 3-2.8-.7-.5-2.2-.7-3.2-.5-1.5.2-1.8-.2-1.3-1.8.6-2.5-1.7-4.8-2.9-2.9z'/%3E%3C/svg%3E%0A");
        width: 15px;
        height: 15px;
        background-size: cover;
        display: inline-block;
        margin-right: 5px;
        color: #000;
      }

      span {
        color: $primary-color;
      }
    }
  }

  .thumbnail-image__container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    overflow-x: auto;
    height: 85%;
    padding: 20px 0;

    .thumbnail-image {
      width: 150px;
      height: 150px;
      position: relative;
      overflow: hidden;
      background: #eee;
      display: flex;
      justify-content: center;
      align-items: center;

      &.passportPhoto {
        width: 200px;
        height: 150px;
      }

      &.documents img {
        width: 35px;
        height: 35px;
      }

      .status {
        position: absolute;
        top: 2px;
        right: 2px;

        .btn.remove {
          font-size: 10px;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          line-height: 12px;
          padding: 0;
        }
      }

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
.drag-over {
  border: dashed 2px $primary-color;
  background: rgba(255,255,255,0.6);
}

.upload-image {
  margin-top: 20px;
  float: right;
}
.upload-success {
  width: 100%;
  height: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 18px;
    font-weight: bold;
  }
}

/* width */
::-webkit-scrollbar {
  width: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000;
}


</style>
