<template>
  <section class="MyTeamCreateBulk">
    <SpaceDescription>
      <Headline :size="1">Bulk Import Persons</Headline>
    </SpaceDescription>
    <Section class="overflow-hidden">
      <Button class="mb-5"
              v-if="this.$route.params.fromAccreditationCreatePage"
              @click.native="navigateToCreateAccreditation">Back to accreditation request</Button>
      <Headline :size="3">Bulk Import Persons</Headline>
    <div v-if="success" >
      <div class="MyTeamCreateBulk__section-success">
        <p class="MyTeamCreateBulk__section-headline text-success">{{ success }}</p>
        <div class="MyTeamCreateBulk__section-check-container">
          <img
              class="MyTeamCreateBulk__section-check"
              :src="$static.icons.check"
              alt=""
          />
        </div>
      </div>
    </div>
    <div v-if="!success" style="margin-bottom: 0;">
      <p
          class="MyTeamCreateBulk__error"
          v-html="error"
      />
      <p> Download template </p>
      <a class="MyTeamCreateBulk__link" :href="templateLink">
        <Button
            class="MyTeamCreateBulk__form-button"
            :style="{marginTop: '10px'}"
        >Download template
        </Button>
      </a>
      <form
          class="MyTeamCreateBulk__form"
          id="MyTeamCreateBulk__form"
          ref="form"
          style="margin-top:50px;"
      >
        <p>Upload completed template</p>
        <div class="file_upload row">
          <div class="file_upload-input col-sm-3">
            <Input type="text"
                   :disableInput="true"
                   :value="filename"
                   @click.native="callInput($event)" />
          </div>
          <div class="file_upload-button col-sm-4">
            <Button
                type="link"
                @click.native="callInput($event)">
              Select file
            </Button>
          </div>
        </div>
        <input
            hidden
            type="file"
            name="file"
            ref="file"
            @change="evaluateFilename()"
        >
      </form>
    </div>
    <div v-if="!success">
      <Button @click.native="sendXml()" :disabled="(filename) ? false : true" >
        <span class="import-btn" :style="{width: '105px'}">Upload&nbsp;here</span>
      </Button>
    </div>
    <div
        class="MyTeamCreateBulk__successful-controls"
        v-else
    >
      <Button
          type="primary"
          @click="resetState()"
      >
        Import more
      </Button>
    </div>

    <DuplicatePersonPopup
        v-if="showPopup"
        contactType="contact"
        :duplicatePersons="duplicatePersons"
        @closePopup="onClose"
        @createDuplicate="createDuplicate"
    />

    <div class="file-hint">
      <Headline :size="3">Bulk Import Accreditation Photos</Headline>
      <div class="row file-hint__container">
        <div class="col-lg-4 file-hint-image">
          <img src="@/assets/dummy-accred-photo-1.png" alt="dummy" width="180"/>
        </div>
        <ul class="col-lg-8 mt-3 mt-lg-0">
          <li> - Name file according to its corresponding passport number: [Passport / ID No.].jpg</li>
          <li> - Photo file size: less than 512 KB</li>
          <li> - Photo type: JPG, JPEG, PNG</li>
          <li> - Photo background: plain white recommended</li>
          <li> - No selfies, no glasses, no hats, no graphic or text</li>
          <li> - Photo should be in color, clear and in focus</li>
          <li class="red-text"> Incorrect photos will be rejected!</li>
        </ul>
      </div>
    </div>

    <BulkFileUpload
        title="Add Accreditation Photos"
        :allowed-file-types="['image/png', 'image/jpeg']"
        :max-size="512*1024"
        :thumbnail-style="{width: '150px', height: '150px'}"
        :upload="accrediationPhotoUpload"
    />

    <div class="file-hint">
      <Headline :size="3">Bulk Import Copy of Passport Photos</Headline>
      <div class="row file-hint__container">
        <div class="col-lg-4 file-hint-image">
          <img src="@/assets/dummy-passport-photo-1.png" alt="dummy" width="280"/>
        </div>
        <ul class="col-lg-8 mt-3 mt-lg-0">
          <li> - Name file according to its corresponding passport number: [Passport / ID No.].jpg</li>
          <li> - File size: less than 512 KB</li>
          <li> - File type: JPG, JPEG, PNG</li>
          <li> - Passport / ID scan should be in color, clear and in focus</li>
          <li class="red-text"> Incorrect files will be rejected!</li>
        </ul>
      </div>
    </div>

    <BulkFileUpload
        title="Add Copy of Passport Photos"
        :allowed-file-types="['image/png', 'image/jpeg']"
        :max-size="512*1024"
        :thumbnail-style="{width: '150px', height: '75px'}"
        :upload="passportPhotoUpload"
    />

    <div class="file-hint">
      <Headline :size="3">Bulk Import Additional Documents</Headline>
      <div class="file-hint__container">
        <ul>
          <li> - Name file according to its corresponding passport number: [Passport / ID No.].pdf</li>
          <li> - If uploading multiple files for one person name like the following:
            <ul>
              <li> File 1: [Passport / ID No.].pdf</li>
              <li> File 2: [Passport / ID No.]-[ANY TEXT].pdf</li>
            </ul>
          </li>
          <li> - Document file size: less then 512KB</li>
          <li> - File type: JPG, JPEG, PNG, PDF</li>
          <li class="red-text"> Incorrect files will be rejected!</li>
        </ul>
      </div>
    </div>

    <BulkFileUpload
        title="Add Copy of document(s)"
        :allowed-file-types="['image/png', 'image/jpeg', 'application/pdf']"
        :max-size="512*1024"
        :thumbnail-style="{width: '150px', height: '150px'}"
        :upload="documentUpload"
    />
    </Section>
  </section>
</template>

<script>
import get from 'lodash.get';
import set from "lodash.set";
import flatten from "lodash.flatten";
import icon from "@/assets/DDMCAF/images/check.svg";
import SpaceDescription from "@/components/SpaceDescription/SpaceDescription.component";
import Headline from "@/components/Headline/Headline.component";
import {DtoApiImportPersonsRequest} from "@/classes/dto/api/person/dto.api.import-persons.request";
import Button from "@/components/Button/Button.component";
import Input from "@/components/Inputs/Input.component"
import Section from "@/components/Section/Section.component";
import RouterNames from "@/router/route.names";
import {TeamMemberModel} from "@/classes/models/TeamMember.model";
import DuplicatePersonPopup from '@/components/Popup/DuplicatePersonPopup.component';
import {TeamMembersCollection} from "@/classes/collections/TeamMembers.collection";
import BulkFileUpload from '@/components/Image/BulkFileUpload.component';
import {ApiRequestor} from "@/classes/requestors/api.requestor";

export default {
  name: 'MyTeamCreateBulk-View',
  components: {
    SpaceDescription,
    Headline,
    Button,
    Input,
    Section,
    DuplicatePersonPopup,
    BulkFileUpload,
  },
  computed: {
    permissions() {
      return this.$store.getters['user/permissions']
    },
    templateLink() {
      const url = new URL(window.apiUrl);
      url.pathname = 'importTemplates/AFG_Person_Import_Template.xlsx';
      return url;
    },
    policyText() {
      return 'By clicking <b>IMPORT</b> and submitting this form I confirm that' +
          ' the information either relates to myself and/or I have the authority' +
          ' and permission to submit the data on behalf of the people named in' +
          ' the attached Excel document and such persons have been made aware of,' +
          ' and agree to, the terms of the' +
          ' <a href=\'' + window.boUrl + '/policy' + '\' target="_blank">AFC Data Protection Policy.</a>'
    }
  },
  watch: {
    permissions() {
      this.checkPermissions()
    }
  },
  data: () => ({
    filename: null,
    success: null,
    error: null,
    showPopup: false,
    duplicatePersons: new TeamMembersCollection(),
    accredPhotoUploadApi: '',
    passportPhotoUploadApi: '',
    documentUploadApi: '',

  }),
  created() {
    this.checkPermissions();
    set(this, '$static.icons.check', icon);
  },
  methods: {
    async accrediationPhotoUpload(file) {
      const payload = {
        organizationId: this.$store.state.user.account.id,
        fileName: file.name,
        data: file.data,
      };
      const client = new ApiRequestor();
      const response = await client.$http('POST', 'persons/accreditationPhoto', {payload});

      if (response.$status !== 200) {
        file.success = false;
        file.error = response.$response.data.message;
      } else {
        file.success = true;
      }
    },
    async passportPhotoUpload(file) {
      const payload = {
        organizationId: this.$store.state.user.account.id,
        fileName: file.name,
        data: file.data,
      };
      const client = new ApiRequestor();
      const response = await client.$http('POST', 'persons/passportPhoto', {payload});

      if (response.$status !== 200) {
        file.success = false;
        file.error = response.$response.data.message;
      } else {
        file.success = true;
      }
    },
    async documentUpload(file) {
      const payload = {
        organizationId: this.$store.state.user.account.id,
        fileName: file.name,
        data: file.data,
      };
      const client = new ApiRequestor();
      const response = await client.$http('POST', 'persons/document', {payload});

      if (response.$status !== 200) {
        file.success = false;
        file.error = response.$response.data.message;
      } else {
        file.success = true;
      }
    },
    checkPermissions() {
      this.permissions.includes('persons:createAPI')
      const permitted = this.permissions.includes('persons:importAPI') && this.$store.getters['user/haveAccessToAccreditation'];
      if (!permitted) this.$router.push({name: RouterNames.Home})
    },
    callInput(event) {
      event.preventDefault();
      this.resetState();
      this.$refs.file.click();
    },
    evaluateFilename() {
      const {form} = this.$refs;
      const [file] = new FormData(form).values();
      this.filename = (!file) ? null : file.name;
    },
    resetState() {
      if (this.$refs.form) this.$refs.form.reset();
      this.filename = null;
      this.setSuccess(null);
    },
    navigateToOverview() {
      const name = RouterNames.IndexRoutes.DashboardRoutes.PersonRoutes.PersonList;
      this.$router.push({name});
    },
    navigateToCreateAccreditation() {
      this.$router.push({name: RouterNames.IndexRoutes.DashboardRoutes.AccreditationRequest})
    },
    async sendXml(skipDuplicateCheck = false) {
      const {form} = this.$refs;
      const payload = new FormData(form);
      const [file] = payload.values();
      if (!file) return this.setError('Microsoft Excel file expected');

      const isExcel = new RegExp('(.xls$)|(.xlsx$)', 'ig').test(file.name);
      if (!isExcel) return this.setError('Microsoft Excel file expected');

      payload.append('organizationId', this.$store.state.user.account.id);
      payload.append('skipDuplicateCheck', skipDuplicateCheck);
      const request = await new DtoApiImportPersonsRequest().$import(payload);
      if (request.$error) {
        const errorsReceived = get(request, '$response.data.data.errors', {});
        const errors = Object.values(errorsReceived).length
            ? flatten(Object.values(errorsReceived))
            : [];
        const message = get(request, '$response.data.message');
        const messages = flatten(errors);
        const error = messages.length ? messages.join('<br/>') : message;
        return this.setError(error);
      }
      if ((request.$status === 200) && request.isFailure && request.isDuplicate) {
        this.duplicatePersons = this.toPersonCollection(request.data.duplicates);
        this.showPopup = true;
        return false;
      }
       this.setSuccess(file.name);
    },
    setError(message) {
      this.success = null;
      this.error = message;
    },
    setSuccess(message) {
      this.success = message;
      this.error = null;
    },
    onClose() {
      this.showPopup = false;
    },
    createDuplicate() {
      this.onClose();
      this.sendXml(true);
    },
    toPersonCollection(duplicates) {
      const collection = new TeamMembersCollection();
      collection.models = duplicates.map(item => {
        const model = new TeamMemberModel();
        [
          'firstName',
          'lastName',
          'birthDate',
          'email',
        ]
            .forEach(key => model[key] = item[key]);
        model.id = item.personId;
        return model;
      });
      return collection;
    },
  }
}
</script>

<style lang="scss">
@import "src/styles/main.scss";

.MyTeamCreateBulk {

  .headline-component {
    margin-top: 0 !important
  }

  .import-btn {
    width: 80px;
  }

  &__section {
    &-success {
      display: flex;
    }

    &-check {
      width: 25px;
      margin: auto;

      &-container {
        margin-left: 20px;
      }
    }
  }

  &__link {
    text-decoration: none;

    &:hover {
      text-decoration: none
    }
  }

  &__successful-controls {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__form {
    margin-top: 10px;

    > p {
      margin-bottom: 10px;
    }

    &-filename {
      margin-left: 10px;
      font-size: 14px;
      position: relative;
      top: -1px;
    }
  }

  &__error {
    margin-top: 5px;
    font-weight: 600;
    font-size: 12px;
    opacity: 0.9;
    line-height: 12px;
    color: red;
  }

  &__success {
    margin-top: 5px;
    font-weight: 600;
    font-size: 10px;
    opacity: 0.9;
    line-height: 10px;
    color: green;
  }

  .file-hint {
    margin-top: 50px;
    width: 75%;

    &__container {
      display: flex;
      background: lighten(desaturate($secondary-color, 60%), 30%);
      padding: 50px;
      border-radius: 12px;
      align-items: center;
      line-height: 1rem;
      margin: 0;

      ul {
        color: #000;

        li {
          margin-bottom: 6px;
          font-size: 12px;
          font-weight: bold;

          > ul {
            margin: 8px 0 8px 20px
          }
        }

        li.red-text {
          color: red;
          margin-top: 20px;
        }
      }
    }

    &-image img {
      min-width: 130px;
      width: 100%;
      max-width: 160px;
    }
  }
  .file_upload-input input {
    cursor: pointer;
  }
}
</style>
